<template>
  <div>
    <h2 v-html="$t('subsidy_production_title')"></h2>
    <!-- Abschlussmonat von -->
    <DbmMonthPicker id="subsidy_production_valid_from" v-model="searchCardStore.validFrom" :dateLabel="$t('subsidy_production_valid_from')" :clearable="true" />
    <!-- bis -->
    <DbmMonthPicker
      id="subsidy_production_valid_until"
      v-model="searchCardStore.validUntil"
      :dateLabel="$t('subsidy_production_valid_until')"
      :clearable="true"
      returnType="lastOfMonth"
    />
    <!-- Kreditor-Nr. (Bewirtschafter) -->
    <v-text-field id="subsidy_production_sap_no" v-model="searchCardStore.sapNo" :label="$t('subsidy_production_sap_no')" type="number" @keyup.enter="search" />
    <!-- Korrektur -->

    <v-select
      id="subsidy_production_version"
      v-model="searchCardStore.correction"
      :label="$t('subsidy_production_version')"
      :placeholder="$t('subsidy_production_version_all')"
      :items="correction"
      clearable
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from '@/components/searchCards/baseSearch.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import { useSearchProductionStore } from '@/store/SearchProductionStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchSubsidyProduction',
  extends: baseSearchVue,
  data() {
    return {
      correction: [
        { title: this.$t('subsidy_production_version_yes'), value: true },
        { title: this.$t('subsidy_production_version_no'), value: false }
      ]
    }
  },
  components: {
    DbmMonthPicker
  },
  beforeMount() {
    this.setSearchCardStore(useSearchProductionStore(), true)
  }
})
</script>
